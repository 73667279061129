<template>
    <div>
        <div class="">
            <div class="text-center">
                <h2 class="text-uppercase lined lined-center">Die <span class="text-primary">THERA+</span> App
                </h2>
                <p class="text-muted mb-5">Digitale Therapiepläne und smarte Patientenkommunikation</p>
            </div>
            <div class="row align-items-stretch gx-0">
                <div class="col-lg-6">
                    <div class="p-2 shadow mt-2"><img class="img-fluid" src="@/assets/img/leistungen/app.png" alt=""></div>

                </div>
                <div class="col-lg-6 mt-5 p-5">
                    <div class="p-2 bg-dark">
                        <div class="border border-white border-2 p-lg-5 p-4 d-flex align-items-center">
                            <div class="ps-3">
                                <h4 class="text-white mb-1">Unsere Erfahrungen in Software umgesetzt</h4>
                                <div class="row gy-4 text-white">
                                    <ul class="list-unstyled text-sm">
                                        <hr />
                                        <li class="">
                                            Das ist THERA+: Unsere App für Therapiepraxen, die Ihren Patienten eine
                                            individuelle Behandlung bieten möchten. Mit unserer einfach zu bedienenden
                                            App können Therapiepraxen problemlos individuelle Therapiepläne für jeden
                                            Patienten erstellen und verwalten, sodass diese auch von Zuhause aus an ihren
                                            Therapiezielen arbeiten können. Unser System erleichtert auch die
                                            Terminplanung und -absage, was zu einer besseren Patientenzufriedenheit und
                                            Effizienz führt. Mit THERA+ können Sie sich auf das konzentrieren, was am
                                            wichtigsten ist: Ihre Patienten. Fragen Sie THERA+ einfach an und
                                            verbessern Sie die Behandlungsergebnisse Ihrer Patienten!
                                        </li>
                                        <hr />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="card border-0 mt-5">
                    <div class="card-body py-5 border-top border-2 border-primary">
                        <div class="row gy-4">
                            <div class="col-lg-6 border-end">
                                <div class="px-4">
                                    <h3 class="h5">Funktionen im Überblick</h3>
                                    <ul class="list-unstyled text-primary text-sm">
                                        <hr />
                                        <li class="">
                                            Erstellung von individuellen und standardisierten Therapieplänen
                                        </li>
                                        <li class="mb-2 text-muted">
                                            Sie können auf den Patienten zugeschnittene Therapiepläne erstellen oder aus einem Katalog an vorhandenen Übungen auswählen
                                        </li>
                                        <hr />
                                        <li class="">
                                            Terminverwaltung und Kalenderintegration
                                        </li>
                                        <li class="mb-2 text-muted">
                                            In der THERA+ App sehen Patienten, wann ihre Termine stattfinden. Dort können sie auch Termine absagen oder Terminvorschläge annehmen. So ist für den Patienten auch ganz transparent sichergestellt, dass Absagefristen eingehalten werden oder eventuelle Gebühren erhoben werden.
                                        </li>
                                        <li class="mb-2 text-muted">
                                            Außerdem können Patienten den Terminplan mit ihrem bevorzugten Kalenderprogramm synchronisieren um keine Termine zu verpassen.
                                        </li>
                                        <hr />
                                        <li class="">
                                            Übersicht der Heilmittelverordnungen
                                        </li>
                                        <li class="mb-2 text-muted">
                                            Mit der App können Patienten ohne Probleme einsehen, wieviele Termine noch in der Heilmittelverordnung offen sind und bei Bedarf entsprechend rechtzeitig eine neue Verordnung organisieren
                                        </li>
                                        <hr />
                                        <li class="">
                                            Krankheits- und Ausfallmanagement
                                        </li>
                                        <li class="mb-2 text-muted">
                                            In der Praxisverwaltung kennen Sie das Problem: Ein/e TherapeutIn fällt aus und Sie verbringen Stunden damit, die Patienten über den Ausfall zu informieren.
                                        </li>
                                        <li class="mb-2 text-muted">
                                            Mit der THERA+ App können Sie einfach und automatisiert allen Patienten die Information zukommen lassen und haben sogar einen Überblick, wer die Information ggf. noch nicht zur Kenntnis genommen hat.
                                        </li>
                                        <hr />

                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 justify-content-md-center">
                                <div class="">
                                    <div class="container">
                                        <h2 class="lined">Sie wollen <span class="text-primary">THERA+</span> auch in Ihrer Praxis
                                            nutzen?</h2>
                                        <p class="text-muted mb-4">Wir sind auf der Suche nach Partnerpraxen. Jetzt Anfrage senden</p>
                                        <div class="row gy-4">
                                            <div class="col-lg-12">
                                                <input class="form-control" v-model="name" type="text" name="name"
                                                    placeholder="Name" :disabled="success">
                                            </div>
                                            <div class="col-lg-6">
                                                <input class="form-control" v-model="email" type="text" name="email"
                                                    placeholder="E-Mail" :disabled="success">
                                            </div>
                                            <div class="col-lg-6">
                                                <input class="form-control" v-model="phone" type="tel" name="phone"
                                                    placeholder="Telefon" :disabled="success">
                                            </div>
                                            <div class="col-lg-12">
                                                <input class="form-control" v-model="company" type="text" name="company"
                                                    placeholder="Name der Praxis" :disabled="success">
                                            </div>
                                            <div class="col-lg-12">
                                                <input class="form-control" v-model="address" type="text" name="address"
                                                    placeholder="PLZ / Ort" :disabled="success">
                                            </div>
                                            <div class="col-lg-12">
                                                <textarea class="form-control" v-model="message" name="message" rows="5"
                                                    placeholder="Nachricht" :disabled="success"></textarea>
                                                <small><span class="text-muted">(Beschreiben Sie kurz ihre Praxis.)</span></small>
                                            </div>
                                            <div class="col-lg-12">
                                                <button v-if="!success" :disabled="!enableSubmit" class="btn btn-primary w-100"
                                                    v-on:click="sendApplication"><i class="fas fa-envelope"></i> Senden</button>
                                                <p class="text-success" v-if="success">Vielen Dank für die Nachricht! Wir melden
                                                    uns innerhalb der
                                                    nächsten zwei Tage zurück.</p>
                                                <p class="text-danger" v-if="error">Oops. Da ist etwas schief gelaufen. Probiers
                                                    gerne nochmal oder
                                                    ruf uns einfach an: <a v-bind:href="`tel:${$t('frame.phone')}`">{{
                                                        $t('frame.phone') }}</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <div class="row">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            success: false,
            error: false,
            name: '',
            company: '',
            address: '',
            email: '',
            phone: '',
            message: ''
        };
    },
    computed: {
        enableSubmit() {
            return true;
        }
    },
    methods: {
        sendApplication() {
            this.success = false;
            this.error = false;
            this.axios.post('https://das-viavitum.de/api/app/', {
                name: this.name,
                email: this.email,
                phone: this.phone,
                message: this.message,
                company: this.company,
                address: this.address
            }).then((response) => {
                if (response.data.status === 1) {
                    this.success = true;
                } else {
                    this.error = true;
                }
            }).catch((error) => {
                console.error(error);
                this.error = true;
            });
        }
    }
};
</script>

<style scoped>
.slider-background {
    width: 100%;
    object-fit: cover;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
